import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const Header = ({ siteTitle }) => (
  <header className="px-8 py-4">
    <div
      style={{
        margin: `0 auto`,
        maxWidth: 960,
        padding: `1.45rem 1.0875rem`,
      }}
      className="flex"
    >
      <h1 style={{ margin: 0 }} className="block w-1/2 text-lg sm:text-4xl">
        <Link
          to="/"
          style={{
            color: `black`,
            textDecoration: `none`,
          }}
        >
          {siteTitle}
        </Link>
      </h1>
      <div className="flex flex-row-reverse w-1/2 relative">
        <a href="/">
          <span className="text-md justify-end absolute bottom-0 underline">
            TOP
          </span>
        </a>
      </div>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: `Elegant Selection`,
}

export default Header
